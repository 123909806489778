CSS for the small header sliding down from the top @keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-down {
  animation: slideDown 1s forwards;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Styles for the scrollbar */
.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-thumb-blue-500 {
  scrollbar-color: #4299e1 transparent; /* Use your theme blue or any preferred color */
}

/* For WebKit browsers like Chrome and Safari */
.scrollbar-thin::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollbar-thumb-blue-500::-webkit-scrollbar-thumb {
  background-color: #4299e1; /* thumb color */
  border-radius: 4px;
}

.scrollbar-track-gray-100::-webkit-scrollbar-track {
  background: #f7fafc; /* track color */
}
@keyframes skeleton {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Optional: Enhance the global style for rounded elements */
/* .rounded-lg {
  overflow: hidden;
} */

/* App.css */

/* Custom styles for the range input thumb */
.range-input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 40px; /* Width of thumb */
  height: 40px; /* Height of thumb */
  background: black; /* Thumb color */
  border-radius: 50%; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.range-input::-moz-range-thumb {
  width: 40px; /* Width of thumb */
  height: 40px; /* Height of thumb */
  background: black; /* Thumb color */
  border-radius: 50%; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.range-input::-ms-thumb {
  width: 40px; /* Width of thumb */
  height: 40px; /* Height of thumb */
  background: black; /* Thumb color */
  border-radius: 50%; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}
